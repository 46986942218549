import { IPublicClientApplication } from '@azure/msal-browser';
import { Button, ButtonGroup, Modal, Text, useDisclosure } from '@lego/klik-ui';
import { parseISO } from 'date-fns';
import { FC, ReactNode, useMemo, useState } from 'react';
import { BareFetcher, SWRConfig } from 'swr';
import { depictObjectKeyType } from '../helpers/keys-as-keyof';
import { useErrorToast } from '../hooks/use-error-toast';
import { ProblemJson } from '../models/problem-json';
import { ProblemJsonError } from '../models/problem-json-error';

const convertDate = (value: string): Date | string => {
  const date = parseISO(value);

  if (!isNaN(date.getTime())) {
    return date;
  }

  return value;
};

//this is shamelessly stolen from the angular utils
//it should probably be stuffed into a new library somewhere
const autoConvertGet = <T,>(entity: T): T => {
  depictObjectKeyType<T>(entity).forEach((key) => {
    const value = entity[key];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!value) {
      return;
    }

    if (typeof value === 'object') {
      autoConvertGet<typeof value>(value);

      return;
    }

    if (typeof value === 'string' && isNaN(Number(value))) {
      entity[key] = convertDate(value) as unknown as T[keyof T];

      return;
    }
  });

  return entity;
};

const fetcherWithApiAuth =
  <T,>(msalInstance: IPublicClientApplication): BareFetcher<T> =>
  async (path: string, init?: RequestInit): Promise<T> => {
    let fetchResult: Promise<Response>;

    if (path.indexOf('/v1/')) {
      const { accessToken } = await msalInstance.acquireTokenSilent({
        scopes: ['d97648d5-afe1-4b4e-b4cb-ee93fcfa4360/openid'],
      });

      const headers = {
        ...init?.headers,
        Authorization: `Bearer ${accessToken}`,
      };

      fetchResult = fetch(path, { ...init, headers });
    } else {
      fetchResult = fetch(path, init);
    }

    const response = await fetchResult;

    if (response.status === 204) {
      return undefined as unknown as T;
    }

    const responseData = (await response.json()) as T | ProblemJson;

    if (!response.ok) {
      throw new ProblemJsonError(`Error returned from ${path}`, responseData as ProblemJson);
    }

    return autoConvertGet(responseData as T);
  };

interface SWRProviderProps {
  msalInstance: IPublicClientApplication;
  children: ReactNode;
}

const SWRProvider: FC<SWRProviderProps> = ({ msalInstance, children }) => {
  const errorToast = useErrorToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState<ProblemJsonError | undefined>();
  const fetcher = useMemo(() => fetcherWithApiAuth(msalInstance), [msalInstance]);

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (swrError) => {
          if (swrError instanceof ProblemJsonError) {
            errorToast(swrError, onOpen);
            setError(swrError);
          }
        },
      }}
    >
      {children}
      {error ? (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" status="error" closeOnOverlayClick>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              {error.problemJson.status} - {error.problemJson.detail ?? error.problemJson.title}
            </Modal.Header>
            <Modal.CloseButton />
            <Modal.Body>
              <Text display="block" fontFamily="monospace" whiteSpace="pre">
                {JSON.stringify(error.problemJson.exceptionDetails, undefined, 2)}
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <ButtonGroup>
                <Button onClick={onClose}>Close</Button>
              </ButtonGroup>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      ) : undefined}
    </SWRConfig>
  );
};

export default SWRProvider;
