import { Table } from '@lego/klik-ui';
import { format } from 'date-fns';
import { JSX } from 'react';
import { CauseGetContract, EntryGetContract } from '../../models/entry/entry-get-contract';

interface EntryRowProps {
  value: EntryGetContract;
}

export const EntryRow = ({
  value: { code, createdBy, createdDate, error, longDescription, mouldId, shortDescription },
}: EntryRowProps): JSX.Element => {
  const getCauseNames = (causes?: CauseGetContract[]) => {
    return causes?.map((cause) => cause.name);
  };

  return (
    <Table.Row verticalAlign="top">
      <Table.Cell>{mouldId}</Table.Cell>
      <Table.Cell>{createdBy?.shortName}</Table.Cell>
      <Table.Cell>{format(createdDate, 'dd/MM/yyyy')}</Table.Cell>
      <Table.Cell>{code.code}</Table.Cell>
      <Table.Cell>{code.description}</Table.Cell>
      <Table.Cell>{code.entryCodeType}</Table.Cell>
      <Table.Cell>{error?.errorCode.code}</Table.Cell>
      <Table.Cell>{error?.description}</Table.Cell>
      <Table.Cell>{getCauseNames(error?.causes)?.join(',')}</Table.Cell>
      <Table.Cell>{shortDescription}</Table.Cell>
      <Table.Cell fontFamily="monospace" whiteSpace="pre">
        {longDescription}
      </Table.Cell>
    </Table.Row>
  );
};
