import { PublicClientApplication } from '@azure/msal-browser';
import { KlikProvider } from '@lego/klik-ui';
import { FC, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { msalConfig } from './auth-config';
import { Layout } from './pages/Layout';
import { Result } from './pages/Result';
import { Search } from './pages/Search';
import AuthProvider from './providers/AuthProvider';
import SWRProvider from './providers/SWRProvider';

const App: FC = () => {
  const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);

  return (
    <AuthProvider msalInstance={msalInstance}>
      <KlikProvider includeFont>
        <SWRProvider msalInstance={msalInstance}>
          <Router>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Search />} />
                <Route path="/result" element={<Result />} />
              </Route>
            </Routes>
          </Router>
        </SWRProvider>
      </KlikProvider>
    </AuthProvider>
  );
};

export default App;
