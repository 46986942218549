import { CloseButton, InlineNotification, Link } from '@lego/klik-ui';
import { FC } from 'react';
import { ProblemJsonError } from '../models/problem-json-error';

export interface ErrorToastProps {
  error: ProblemJsonError;
  onDetails?: () => void;
  onClose: () => void;
}

export const ErrorToast: FC<ErrorToastProps> = ({ error: { problemJson }, onClose, onDetails }) => {
  return (
    <InlineNotification variant="error">
      <InlineNotification.Content alignItems="flex-start" flexDirection="column">
        <InlineNotification.Title>{problemJson.title}</InlineNotification.Title>
        <InlineNotification.Description>
          {problemJson.status} - {problemJson.detail}
        </InlineNotification.Description>
        {onDetails ? (
          <InlineNotification.Description>
            <Link onClick={onDetails}>Details</Link>
          </InlineNotification.Description>
        ) : (
          ''
        )}
      </InlineNotification.Content>
      <CloseButton aria-label="" onClick={onClose} />
    </InlineNotification>
  );
};
