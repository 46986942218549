import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { API_ROOT } from '../constants';
import { EntryCodeGetContract } from '../models/entry/entry-get-contract';
import { PagedEnvelope } from '../models/paged-envelope';
import { ProblemJsonError } from '../models/problem-json-error';

const useEntryCodes = (): SWRResponse<PagedEnvelope<EntryCodeGetContract>, ProblemJsonError> =>
  useSWRImmutable<PagedEnvelope<EntryCodeGetContract>, ProblemJsonError>(`${API_ROOT}entry-codes`);

export default useEntryCodes;
