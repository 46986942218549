import styled from '@emotion/styled';
import { Button, Flex, IButtonProps } from '@lego/klik-ui';
import { JSX, useMemo } from 'react';

interface BrickButtonProps extends IButtonProps {
  knobCount?: number;
  children: string;
}

const Knob = styled.div`
  border-style: solid solid none solid;
  border-width: 2px;
  border-radius: 2px 2px 0 0;
  border-color: #247bbf;
  width: 15px;
  height: 6px;
`;

export const BrickButton = ({
  knobCount = 3,
  children,
  ...props
}: BrickButtonProps): JSX.Element => {
  const knobs = useMemo(
    () => Array.from({ length: knobCount }, (_, i) => <Knob key={i} />),
    [knobCount],
  );

  return (
    <Button variant="outline" {...props}>
      <Flex position="absolute" top="-8px" width="100%" justifyContent="space-around">
        {knobs}
      </Flex>
      {children}
    </Button>
  );
};
