/* eslint-disable */
import { useMemo } from 'react';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { ENTRIES_ROOT } from '../../constants';
import { EntryFilterContract } from '../../models/entry/entry-filter-contract';
import { EntryGetContract } from '../../models/entry/entry-get-contract';
import { PagedEnvelope } from '../../models/paged-envelope';
import { ProblemJsonError } from '../../models/problem-json-error';

const useEntries = (
  filter: EntryFilterContract
): SWRResponse<PagedEnvelope<EntryGetContract>, ProblemJsonError> => {
  const filterAsJson = useMemo(() => JSON.stringify(filter), [filter]);
  const { data: contract, ...response } = useSWRImmutable<
    PagedEnvelope<EntryGetContract>,
    ProblemJsonError
  >(`${ENTRIES_ROOT}?q=${filterAsJson}`);

  const data = useMemo(() => contract, [contract]);

  return {
    ...response,
    data,
  };
};

export default useEntries;
