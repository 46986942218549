import { Flex, Stack, Text } from '@lego/klik-ui';
import { JSX, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { EntryTable } from '../components/EntryTable';
import { Loader } from '../components/Loader';
import SearchForm from '../components/SearchForm';
import useEntries from '../hooks/entry/use-entry';
import { EntryFilterContract } from '../models/entry/entry-filter-contract';

export const Result = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = JSON.parse(searchParams.get('q') ?? '') as EntryFilterContract;

  const form = useForm<EntryFilterContract>({
    defaultValues: q,
  });

  const results = useEntries(q);
  const resultSrc = useMemo(() => results.data, [results.data]);

  const onSubmit = useCallback(
    (entryFilter: EntryFilterContract) => {
      setSearchParams({ q: JSON.stringify(entryFilter) });
    },
    [setSearchParams],
  );

  return (
    <Stack overflowX="auto">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SearchForm inline />
        </form>
      </FormProvider>
      <Loader isLoading={results.isLoading}>
        {resultSrc && resultSrc.value.length > 0 && <EntryTable entries={resultSrc.value} />}
        {resultSrc && !resultSrc.value.length && (
          <Flex justifyContent="space-around">
            <Text>
              No results found when searching for MouldIds {JSON.stringify(q.mouldIds)}
              {!q.codes || !q.codes.length ? '' : ` With the codes ${JSON.stringify(q.codes)}`}
            </Text>
          </Flex>
        )}
      </Loader>
    </Stack>
  );
};
