import { Flex } from '@lego/klik-ui';
import { JSX, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import { EntryFilterContract } from '../models/entry/entry-filter-contract';

export const Search = (): JSX.Element => {
  const form = useForm<EntryFilterContract>({
    defaultValues: {
      mouldIds: undefined,
      codes: new Array<string>(),
    },
  });

  const navigate = useNavigate();

  const onSubmit = useCallback(
    (entryFilter: EntryFilterContract) => {
      const searchParams = new URLSearchParams({
        q: JSON.stringify(entryFilter),
      });

      navigate({ pathname: '/result', search: searchParams.toString() });
    },
    [navigate],
  );

  return (
    <FormProvider {...form}>
      <Flex flexDirection="column" justifyContent="space-around" height="100%">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SearchForm />
        </form>
      </Flex>
    </FormProvider>
  );
};
