import { Container } from '@lego/klik-ui';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';

export const Layout: FC = () => {
  return (
    <>
      <Header />
      <Container height="calc(100% - 72px)" maxW="full">
        <Outlet />
      </Container>
    </>
  );
};
