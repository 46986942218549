/* eslint-disable */
import { Box, Dropdown, Flex, FormControl, Image, Input, Select, Stack } from '@lego/klik-ui';
import { Search as SearchIcon } from '@lego/klik-ui/icons';
import { FC, useCallback, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import monkeyBanana from '../assets/monkey-banana.png';
import useEntryCodes from '../hooks/use-entry-codes';
import { EntryFilterContract } from '../models/entry/entry-filter-contract';
import { BrickButton } from './table-assets/brick-button/BrickButton';

interface SearchProps {
  inline?: boolean;
}

const SearchForm: FC<SearchProps> = ({ inline }) => {
  const { control } = useFormContext<EntryFilterContract>();

  const entryCodes = useEntryCodes();

  const entryCodesSrc = useMemo(
    () =>
      entryCodes.data?.value.map((x) => ({
        value: x.code,
        label: <>{x.code}</>,
        text: x.code,
      })),
    [entryCodes.data],
  );

  const codesDropdownRender = useCallback<
    ({
      field,
    }: {
      field: ControllerRenderProps<EntryFilterContract, 'codes'>;
    }) => React.ReactElement
  >(
    ({ field: { onChange, onBlur, value } }) => {
      return entryCodesSrc ? (
        <Dropdown
          listHeight="40vh"
          onChange={onChange}
          value={value}
          isMultiple
          selectionIndicator="check"
          onBlur={onBlur}
          shouldDisplaySelectionLabels
          isLazy={false}
        >
          {entryCodesSrc.map((option) => (
            <Dropdown.Option key={option.value} value={option.value} text={option.text}>
              {option.label}
            </Dropdown.Option>
          ))}
        </Dropdown>
      ) : (
        <></>
      );
    },
    [entryCodesSrc],
  );

  const makeArray = useCallback(
    (value: string) =>
      value
        .split(',')
        .map((x) => x.trim())
        .map((x) => (x != '' ? Number(x) : x))
        .filter((x) => Number.isInteger(x) || x == '')
        .map((x) => x.toString()),
    [],
  );

  return (
    <Stack gap={'4rem'} margin={'4rem 4rem 0'}>
      <Image src={monkeyBanana} width={'750px'} margin={'auto'}></Image>
      <Flex direction={inline ? 'row' : 'column'} gap="1em">
        <Flex flex={1} direction="row" justifyContent="space-between" gap="1em">
          <Box flex={1}>
            <FormControl isRequired>
              <FormControl.Label size="sm">Mould Id</FormControl.Label>
              <Controller
                name="mouldIds"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input.Group>
                    <Input.RightElement pointerEvents="none">
                      <SearchIcon />
                    </Input.RightElement>
                    <Input
                      onChange={(value) => {
                        onChange(makeArray(value.target.value));
                      }}
                      onBlur={onBlur}
                      value={value?.join(',') ?? ''}
                      placeholder="E.g 3001269. Search multiple with a comma-separated list"
                    />
                  </Input.Group>
                )}
              ></Controller>
            </FormControl>
          </Box>
          <Box flex={1}>
            <FormControl isRequired>
              <FormControl.Label size="sm">Select specific entry codes</FormControl.Label>

              {entryCodesSrc ? (
                <Controller control={control} name="codes" render={codesDropdownRender} />
              ) : (
                <Select>
                  <option>Loading...</option>
                </Select>
              )}
            </FormControl>
          </Box>
        </Flex>
        <Flex
          direction={inline ? 'column' : 'row'}
          justifyContent={inline ? 'flex-end' : 'space-around'}
        >
          <BrickButton rightIcon={<SearchIcon />} type="submit">
            Search
          </BrickButton>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default SearchForm;
