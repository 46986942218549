import { CircularProgress, Flex } from '@lego/klik-ui';
import { FC, PropsWithChildren } from 'react';

interface LoaderProps {
  isLoading: boolean;
}

export const Loader: FC<PropsWithChildren<LoaderProps>> = ({ isLoading, children }) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <Flex justifyContent="space-around">
      <CircularProgress size="lg" isIndeterminate />
    </Flex>
  );
};
