import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';

interface ActiveAccountProviderProps {
  children: ReactNode;
}

interface AuthProviderProps {
  msalInstance: PublicClientApplication;
}

const ActiveAccountProvider: FC<ActiveAccountProviderProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const activeAccount = useAccount();
  const firstAccount = accounts[0];

  useEffect(() => {
    if (!activeAccount && accounts.length) {
      instance.setActiveAccount(firstAccount);
    }
  }, [activeAccount, accounts.length, firstAccount, instance]);

  // eslint-disable-next-line no-null/no-null
  return activeAccount ? <>{children}</> : null;
};

const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({ msalInstance, children }) => (
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ActiveAccountProvider>{children}</ActiveAccountProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

export default AuthProvider;
