import { ProblemJson } from './problem-json';

export class ProblemJsonError extends Error {
  problemJson: ProblemJson;

  constructor(message: string, problemJson: ProblemJson) {
    super(message);
    this.problemJson = problemJson;
  }
}
