import { Table } from '@lego/klik-ui';
import { FC, useMemo } from 'react';
import { EntryGetContract } from '../models/entry/entry-get-contract';
import { EntryRow } from './table-assets/EntryRow';

interface EntryTableProps {
  entries: EntryGetContract[];
}

export const EntryTable: FC<EntryTableProps> = ({ entries }: EntryTableProps) => {
  const rows = useMemo(
    () => entries.map((entry) => <EntryRow key={entry.id} value={entry} />),
    [entries],
  );

  return (
    <Table css={{ tableLayout: 'auto' }}>
      <Table.Head>
        <Table.Row>
          <Table.ColumnHeader>Mould ID</Table.ColumnHeader>
          <Table.ColumnHeader>Created by</Table.ColumnHeader>
          <Table.ColumnHeader>Created date</Table.ColumnHeader>
          <Table.ColumnHeader>Entry code</Table.ColumnHeader>
          <Table.ColumnHeader>Entry code description</Table.ColumnHeader>
          <Table.ColumnHeader>Entry code type</Table.ColumnHeader>
          <Table.ColumnHeader>Error code</Table.ColumnHeader>
          <Table.ColumnHeader>Error description</Table.ColumnHeader>
          <Table.ColumnHeader>Error causes</Table.ColumnHeader>
          <Table.ColumnHeader>Short description</Table.ColumnHeader>
          <Table.ColumnHeader>Long description</Table.ColumnHeader>
        </Table.Row>
      </Table.Head>
      <Table.Body>{rows}</Table.Body>
    </Table>
  );
};
