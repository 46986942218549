import { LEGOLogo, Navbar, Spacer, Text } from '@lego/klik-ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Header: FC = () => {
  return (
    <Navbar>
      <Navbar.Brand as={Link} to="/">
        <LEGOLogo mr="24px" />
        <Text>Mouldify</Text>
      </Navbar.Brand>
      <Spacer />
      <Navbar.Segment>{/* <Profile /> */}</Navbar.Segment>
    </Navbar>
  );
};
